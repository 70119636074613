export default defineEventHandler((event) => {
if (process.env.NODE_ENV === 'development') {
    // Modo desarrollo: Desactivar el indexado
    return send(event, 'User-agent: *\nDisallow: /', {
    headers: {
        'Content-Type': 'text/plain'
    }
    });
} else {
    // Modo producción: Permitir el indexado
    return send(event, 'User-agent: *\nAllow: /', {
    headers: {
        'Content-Type': 'text/plain'
    }
    });
}
});